import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import loadable from "@loadable/component"

// import useAvailableRegion from "../../hooks/useAvailableRegion"
import { headerHeight } from "../../utils/constant"
import GatsbyImageBox from "@/components/GatsbyImageBox"

// const AppStoreButtons = loadable(() => import("./AppStoreButtons"))

const Overlay = () => (
  <Box
    position="absolute"
    width="full"
    height="full"
    backgroundColor="#0F0F0F"
    opacity="0.55"
  />
)

const query = graphql`
  {
    bannerBackgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "banner/background.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    bannerPhonePreview: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "banner/phone-preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    bannerPhonePreviewIsolatedSection: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "banner/phone-preview-isolated-section.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`

const Banner = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)
  // const { loading, available } = useAvailableRegion()

  return (
    <>
      <Flex
        as="section"
        width="full"
        position="relative"
        alignItems="center"
        minHeight={{
          base: `calc(80vh - ${headerHeight})`,
          sm: `calc(100vh - ${headerHeight})`,
        }}
      >
        <GatsbyImageBox
          loading="eager"
          image={getImage(data.bannerBackgroundImage)}
          position="absolute"
          width="full"
          height="full"
          alt="yaraconnect banner background"
        />
        <Overlay />

        {/* Banner content */}
        <Flex
          position="relative"
          width="full"
          height="full"
          alignItems="center"
          justifyContent="center"
          maxHeight={{
            base: `calc(80vh - ${headerHeight})`,
            sm: `calc(100vh - ${headerHeight})`,
          }}
        >
          <Flex
            direction={{ base: "column", sm: "row" }}
            alignItems="center"
            mx="auto"
            maxW="6xl"
            px="6"
            width="full"
            height="full"
          >
            <Flex
              color="white"
              direction="column"
              width={{ base: "100%", sm: "65%" }}
              py="10"
              pr={{ base: "0", sm: "6" }}
              height="full"
            >
              <Heading
                fontWeight="300"
                textAlign={{ base: "center", sm: "inherit" }}
                fontSize={{ base: "1.75rem", md: "3.5rem" }}
                lineHeight={{ base: "2.35rem", md: "4rem" }}
                py={{ base: 4, md: 6 }}
              >
                {t("home-page.banner-section.title")}
              </Heading>
              {/* <AppStoreButtons
                chakraStyleProps={{
                  mt: "4",
                  justifyContent: { base: "center", sm: "flex-start" },
                  spacing: "6",
                }}
                naChakraStyleProps={{
                  mt: "4",
                  alignItems: { base: "center", sm: "flex-start" },
                  spacing: "3",
                }}
                loading={loading}
                available={available}
              /> */}
            </Flex>

            <Flex
              width={{ base: "100%", sm: "35%" }}
              justifyContent="center"
              p="8"
              display={{ base: "none", sm: "initial" }}
            >
              <GatsbyImageBox
                image={getImage(data.bannerPhonePreview)}
                width="full"
                alt="yaraconnect app"
                pointerEvents="none"
              />
              <Box alt="yaraconnect app" pointerEvents="none" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* Phone preview isolated section (only on mobile) */}
      <Box
        as="section"
        width="full"
        position="relative"
        display={{ base: "initial", sm: "none" }}
      >
        <Box p="6" height="full" background="ahuaOrange.5">
          <GatsbyImageBox
            image={getImage(data.bannerPhonePreviewIsolatedSection)}
            alt="yaraconnect phone preview image"
            width="60%"
            mx="auto"
          />
        </Box>
      </Box>
    </>
  )
}

export default Banner
